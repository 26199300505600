import { useRef, useEffect, useState } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { MealVisited } from "src/api/meals";
import { capitalizeWords, LOGGING } from "src/constants";
import { RootState } from "src/store";
import {
  readTopRestaurants,
  readTopRestaurantUserStats,
  setRestaurantWantsToTry,
  setRestaurantHasTried,
  setRestaurantStarRating,
  setRestaurantReview,
} from "src/store/actions";
import styled from "styled-components";
import { BackToTop } from "./components/customers/mealMenu/BackToTop";
import SharePage from "./components/referrals/SharePage";
import RestaurantCountWithProgress from "./components/restaurants/RestaurantCountWithProgress";
import RestaurantThumbnail, {
  RestaurantThumbnailEmpty,
} from "./components/restaurants/RestaurantThumbnail";
import { awardName } from "./components/restaurants/RestaurantThumbnail";
import {
  Centered,
  CenteredByColumn,
  textTitle,
  ALLCAPTEXT,
  CenteredByColumnLeft,
  Beige,
  text12300,
  BorderGrey,
  Text12300,
} from "./components/Shared";
import { Back, Loading } from "../shared";
import BackOrHome from "../shared/BackOrHome";

const ContentContainer = styled(CenteredByColumn)`
  background-color: ${Beige};
  justify-content: flex-start;
  min-height: 100vh;
  > button {
    z-index: 100;
  }
  @media screen and (min-width: 800px) {
    padding: 10px calc(50vw - 400px);
  }
`;

const Select = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  color: black; /* Ensure text color is black */

  /* Override iOS and mobile browser styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Add a custom background for the dropdown arrow */
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;

  /* Handle focus state */
  &:focus {
    outline: none;
    border-color: #666;
    box-shadow: 0 0 3px #999;
  }

  /* Style for the options (this might not apply directly but helps future compatibility) */
  option {
    background-color: white;
    color: black; /* Ensure the selected option's color is black */
  }
`;

const List = styled(CenteredByColumn)`
  /* justify-content: flex-start; */
  flex-wrap: wrap;
  padding: 20px;
  align-items: flex-start;
  width: 100%;
  @media screen and (min-width: 500px) {
    flex-direction: row;
    width: 100%;
  }
`;
const LoadingText = styled(Text12300)``;
const Header = styled(Centered)`
  ${textTitle}
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: transparent;
`;
const SubHeader = styled(Centered)`
  ${ALLCAPTEXT}
  font-size: 13px;
  margin-bottom: 20px;
`;

const FilterContainer = styled(Centered)<{ isSticky: boolean }>`
  width: 100%;
  padding: ${({ isSticky }) =>
    isSticky ? "10px 20px 10px 60px" : "10px 20px"};
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${Beige};
  border-bottom: ${({ isSticky }) =>
    isSticky ? `1px solid ${BorderGrey}` : "none"};
  @media screen and (min-width: 500px) {
    /* width: 100vw; */
    padding: 10px 40px;
    max-width: 800px;
    /* select {
      width: 200px;
    } */
    > button {
      display: none;
    }
  }
`;
const Essay = styled(CenteredByColumnLeft)`
  padding: 0px 20px;
  max-width: 500px;
  p {
    ${text12300}
    max-width: 800px;
    line-height: 1.8;
    /* text-align: center; */
    /* margin: 10px 0px; */
  }
`;
const RestaurantCount = styled(Centered)`
  ${text12300}
  width: 100%;
  max-width: 500px;
  text-align: left;
  justify-content: flex-start;
  padding: 0px 20px;
  @media screen and (min-width: 500px) {
    justify-content: center;
    text-align: center;
  }
`;
const SelectContainer = styled(CenteredByColumnLeft)`
  flex-grow: 1;
  width: 33%;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  label {
    ${text12300}
  }
  select {
    width: 100%;
  }
`;
const Nav = styled(CenteredByColumn)`
  width: 100%;
  max-width: 700px;
  position: relative;
`;
const PageTopRestaurants = () => {
  const name = useSelector(
    (state: RootState) =>
      state?.currentUser?.user?.firstName +
      " " +
      state?.currentUser?.user?.lastName
  );
  const [loading, setLoading] = useState(false);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const filterRef = useRef<HTMLDivElement | null>(null);
  const [city, setCity] = useState("all"); // City filter state, default to "all"
  const [category, setCategory] = useState("all");
  const [level, setLevel] = useState("all");
  const firstName = useSelector(
    (state: RootState) => state?.currentUser?.user?.firstName
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state?.currentUser?.isAuthenticated
  );

  const topRestaurants = useSelector(
    (state: RootState) => state.topRestaurants
  );
  // LOGGING && console.log("PageTopRestaurants got from state:", topRestaurants);

  useEffect(() => {
    if (topRestaurants.length === 0) {
      MealVisited("Restaurant Guide 2025", name);
      setLoading(true);
      readTopRestaurants()(dispatch, store.getState).then((res) => {
        setLoading(false);
        LOGGING &&
          console.log("readTopRestaurants got response from server: ", res);
        const restaurantIds = res.map((r) => r.restaurantId);
        readTopRestaurantUserStats(restaurantIds)(
          dispatch,
          store.getState
        ).then((res) => {
          LOGGING &&
            console.log(
              "readTopRestaurantUserStats got response from server: ",
              {
                res,
              }
            );
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      MealVisited("Restaurant Guide 2025", name);
      readTopRestaurants()(dispatch, store.getState).then((res) => {
        LOGGING &&
          console.log("readTopRestaurants got response from server: ", res);

        const restaurantIds = res.map((r) => r.restaurantId);
        readTopRestaurantUserStats(restaurantIds)(
          dispatch,
          store.getState
        ).then((res) => {
          LOGGING &&
            console.log(
              "readTopRestaurantUserStats got response from server: ",
              {
                res,
              }
            );
        });
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let filtered = topRestaurants;
    if (city !== "all") {
      filtered = filtered.filter((restaurant) => restaurant.city === city);
    }
    if (category !== "all") {
      filtered = filtered.filter(
        (restaurant) => restaurant.awardCategory === category
      );
    }
    if (level !== "all") {
      filtered = filtered.filter(
        (restaurant) => (restaurant.awardLevel || 1).toString() === level
      );
    }
    console.log("Filtered restaurants: ", {
      city,
      category,
      level,
      filtered,
    });
    setFilteredRestaurants(filtered);
  }, [city, category, level, topRestaurants]);

  const handleWantToTry = async (restaurantId: string, wantsToTry: boolean) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantWantsToTry(restaurantId, wantsToTry)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantWantsToTry response: ", {
          restaurantId,
          wantsToTry: res,
        });
    });
  };

  const handleHasTried = async (restaurantId: string, hasTried: boolean) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantHasTried(restaurantId, hasTried)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantHasTried response: ", {
          restaurantId,
          hasTried: res,
        });
    });
  };

  const handleStarRating = async (restaurantId: string, starRating: number) => {
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantStarRating(restaurantId, starRating)(
      dispatch,
      store.getState
    ).then((res) => {
      LOGGING &&
        console.log("setRestaurantStarRating response: ", {
          restaurantId,
          starRating: res,
        });
    });
  };

  const handleReview = async (restaurantId: string, review: string) => {
    console.log("handleReview called: ", { restaurantId, review });
    const userId = store.getState().currentUser?.user?._id;
    if (!userId) {
      history.push({
        pathname: "/auth",
        state: {
          title: `To add a note on Foodie guide`,
          next: location?.pathname,
        },
      });
      return;
    }
    setRestaurantReview(restaurantId, review)(dispatch, store.getState).then(
      (res) => {
        LOGGING &&
          console.log("setRestaurantReview response: ", {
            restaurantId,
            review: res,
          });
      }
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (filterRef.current) {
        const rect = filterRef.current.getBoundingClientRect();
        const sticky = rect.top <= 0;
        if (sticky !== isSticky) {
          setIsSticky(sticky);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  LOGGING && console.log("PageTopRestaurants rendering with", topRestaurants);
  const cities = Array.from(new Set(topRestaurants.map((r) => r.city))).sort();
  const categories = Array.from(
    new Set(topRestaurants.map((r) => r.awardCategory.toLowerCase()))
  ).sort();
  const levels = Array.from(
    new Set(topRestaurants.map((r) => r.awardLevel || 1))
  ).sort();
  const numberHasTried = topRestaurants.filter((r) => r.hasTried).length;

  return (
    <ContentContainer>
      <BackOrHome />
      <Nav>
        <Header>Foodie Guide</Header>
        <SubHeader>2025 Edition</SubHeader>
        <SharePage url={"https://foodie.earth/guide2025"} />
      </Nav>
      {!loading && (
        <Essay>
          {/* <p>The South Bay is home not only to some of the largest technology companies in the world but also to over 100,000 restaurants.</p> <p>Based on Yelp reviews and feedback from our foodie community, this guide is perfect for newcomers or those stuck visiting the same few spots near home.</p> <p>We’ve narrowed it down to 50, so you can explore all the recommendations in a month or two with our service. Step out of your comfort zone—you’ll be pleasantly surprised:)</p> */}
          <p>
            {" "}
            This guide introduces {topRestaurants.length} top restaurants from
            various cuisines in the South Bay (out of 100,000+ restaurants!)
            based on Yelp reviews and feedback from our foodie community. You
            can try them all within two months using our service.{" "}
          </p>{" "}
          <p>
            {" "}
            It’s perfect for newcomers or those stuck visiting the same few
            spots near home. Step out of your comfort zone—you’ll be pleasantly
            surprised! :){" "}
          </p>
        </Essay>
      )}
      {loading && <LoadingText>Loading...</LoadingText>}{" "}
      {/* Show loading indicator while fetching */}
      {topRestaurants.length > 0 && !loading && (
        <FilterContainer ref={filterRef} isSticky={isSticky}>
          {/* {isSticky && (
            <Back
              style={{ top: "0px", left: "0px", height: "60px", width: "60px" }}
              onClick={() => window.history.back()}
            />
          )} */}
          <SelectContainer>
            <label htmlFor="city-filter">City</label>
            <Select
              id="city-filter"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option value="all">All</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {capitalizeWords(city)}
                </option>
              ))}
            </Select>
          </SelectContainer>
          <SelectContainer>
            <label htmlFor="category-filter">Cuisine</label>
            <Select
              id="category-filter"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="all">All</option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {capitalizeWords(category)}
                </option>
              ))}
            </Select>
          </SelectContainer>
          <SelectContainer>
            <label htmlFor="category-filter">Award</label>
            <Select
              id="level-filter"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="all">All</option>
              {levels.map((level) => (
                <option key={level} value={level}>
                  {awardName[level - 1]}
                </option>
              ))}
            </Select>
          </SelectContainer>
        </FilterContainer>
      )}
      {topRestaurants.length > 0 && firstName && !loading && (
        <RestaurantCountWithProgress
          numberHasTried={numberHasTried}
          total={topRestaurants.length}
          firstName={firstName}
        />
      )}
      {loading ? (
        <List>
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
          <RestaurantThumbnailEmpty />
        </List>
      ) : (
        <List>
          {filteredRestaurants.map((restaurant, index) => (
            <RestaurantThumbnail
              key={index}
              restaurant={restaurant}
              onWantToTry={handleWantToTry}
              onHasTried={handleHasTried}
              onStarRating={handleStarRating}
              onReview={handleReview}
            />
          ))}
        </List>
      )}
      <BackToTop />
    </ContentContainer>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    topRestaurants: state.topRestaurants,
  };
}

export default connect(mapStateToProps, {
  readTopRestaurants,
})(PageTopRestaurants);
