import { useState, useCallback } from "react";
import { faShare as faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { SendAlert } from "src/api/referrals";
import {
  breakPoint,
  Centered,
  text12300,
  actionBlack,
  centeredByColumn,
  actionUnderline,
  actionGrey,
  BorderGrey,
} from "src/screens/tasting_menu/components/Shared";
import { RootState } from "src/store";
import styled, { keyframes } from "styled-components";

const Container = styled(Centered)`
  position: absolute;
  top: 0px;
  right: 10px;
  height: 85px;
  z-index: 100;
  justify-content: flex-end;
`;

const animation = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const CopiedNote = styled.span`
  position: absolute;
  bottom: -5px;
  right: 0px;
  padding: 2px 5px;
  width: 80px;
  border-radius: 5px;
  /* width: 100px; */
  background: green;
  color: white;
  ${text12300}
  font-weight: 500;
  text-transform: capitalize;
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
  opacity: 0.9;
  animation: ${animation} 1s ease-in-out 0.5s 1 normal forwards;
`;

const GuideLink = styled.button`
  height: 100%;
  width: 100%;
  margin: 0px;
  ${centeredByColumn}
  svg {
    /* display: none; */
    margin-bottom: 5px;
    font-size: 20px;
  }
  @media only screen and (min-width: ${breakPoint}px) {
    /* position: absolute;
    bottom: 30px; */
    display: none;
  }
`;

const CopyLink = styled(Centered)`
  button {
    /* ${actionBlack} */
    /* ${actionUnderline} */
    ${actionGrey}
    background-color: white;
    opacity: 1;
    border: 1px solid ${BorderGrey};
    box-shadow: none;
    font-size: 10px;
    border-radius: 5px;
    svg {
      margin-right: 5px;
    }
    /* margin-left: 10px; */
  }
  display: none;
  @media only screen and (min-width: ${breakPoint}px) {
    display: flex;
    span {
      /* font-family: Arial, Helvetica, sans-serif; */
    }
  }
`;
const SharePage = ({ url, style = {} }) => {
  const [isCodeCopied, setIsCodeCopied] = useState(false);
  const name = useSelector(
    (state: RootState) =>
      state?.currentUser?.user?.firstName +
      " " +
      state?.currentUser?.user?.lastName
  );
  const shareContent = {
    url,
    title: "Foodie Guide 2025",
    text: "",
  };
  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(url);
    setIsCodeCopied(true);
    SendAlert(name, `copied ${url}!`);
    setTimeout(() => {
      setIsCodeCopied(false);
    }, 2000);
  }, [url]);

  const handleShareClick = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareContent);
      } else {
        console.log("Web Share API not supported in this browser.");
        // Fallback for browsers that don't support the Web Share API
        // You can implement your own sharing mechanism here.
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  return (
    <Container style={style}>
      <GuideLink
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          SendAlert(name, `shared ${url}!`);
          handleShareClick();
        }}
      >
        <FontAwesomeIcon icon={faCopy} />
      </GuideLink>
      <CopyLink>
        <button onClick={copyLink}>
          <FontAwesomeIcon icon={faCopy} />
          share
        </button>
      </CopyLink>
      {isCodeCopied ? <CopiedNote>Link copied!</CopiedNote> : null}
    </Container>
  );
};
export default SharePage;
